<template>
  <div>

<!--    <section class="section">-->
<!--      <div class="container has-text-centered">-->
<!--        <h1 class="title is-3">{{ $t('Security token issues') }}</h1>-->
<!--      </div>-->
<!--    </section>-->

    <section class="section">

      <div class="container">

        <div class="content">
          <h3>{{ $t('How to find the factory default password') }}</h3>
          <p>{{ $t('Information about the factory default password (the default password)') }}:</p>
          <p><span v-html="$t('Default password')"></span>.</p>
          <p><span v-html="$t('Default password to the security token JaCarta PKI/ГОСТ, JaCarta PKI and JaCarta JC200')"></span>.</p>

          <h3>{{ $t('If you forgot your password') }}</h3>
          <p>
            {{ $t('If you have forgotten your eToken password, contact the supplier. Maybe they provide unlock methods, otherwise you will have to purchase a new one and') }}
            <router-link :to="{path: '/instructions', name: 'instructions', params: {step: 'reissue'}}">{{ $t('reissue your certificates') }}</router-link>.
          </p>
          <p><span v-html="$t('If you are using JaCarta purchased in Russia, you can unlock the security token using the utility')"></span>.</p>
          <p>
            {{ $t('If you are using JaCarta purchased in Kazakhstan, you will have to format the security token and') }}
            <router-link :to="{path: '/instructions', name: 'instructions', params: {step: 'reissue'}}">{{ $t('reissue your certificates') }}</router-link>.
          </p>
        </div>

        <div style="height: 0.8rem;"></div>

        <div class="content">
          <h3>{{ $t('If the security token is locked') }}</h3>
          <p><span v-html="$t('If the security token JaCarta is blocked as a result of exceeding the allowable number of attempts to enter the wrong password, use the utility')"></span>.</p>
<!--          <router-link :to="{path: '/instructions', name: 'instructions', params: {step: 'JaCarta'}}">{{ $t('Instructions for unlocking devices JaCarta PKI/GOST') }}</router-link>.-->
          <p></p>
          <p>
            <span v-html="$t('If the security token eToken is blocked, you should contact the supplier')"></span>
            <router-link :to="{path: '/instructions', name: 'instructions', params: {step: 'reissue'}}">{{ $t('order for reissue') }}</router-link>.
          </p>
        </div>

        <div class="container has-text-centereds">
          <div class="columns has-text-centered">
            <div class="column">
              <figure class="image is-96x96 is-center"><img src="@/assets/img/part_aknur.png"></figure>
              <a href="http://aknur.kz/etoken-dlya-samruk-kazyna/etoken-dlya-polzovateley-nerezidentov-isez-ao-fnb-samruk-kazyna.html?lang=ru" target="_blank">SafeNet eToken 5110</a>
              <p>{{ $t('For Kazakhstan') }}</p>
            </div>
            <div class="column">
              <figure class="image is-96x96 is-center"><img src="@/assets/img/part_alladin2.png"></figure>
              <a href="https://iecp.ru/services/nositeli/token-dlya-samruk-kazyna" target="_blank">JaCarta PKI/ГОСТ</a>
              <p>{{ $t('For Russia') }}</p>
            </div>
            <div class="column">
              <figure class="image is-96x96 is-center"><img src="@/assets/img/part_alladin.png"></figure>
              <a href="http://www.ealaddin.com.ua/7-novosti/78-autentifikatsiya-na-torgovykh-ploshchadkakh.html" target="_blank">SafeNet eToken 5110</a>
              <p>{{ $t('For Ukraine') }}</p>
            </div>
<!--            <div class="column">-->
<!--              <figure class="image is-96x96 is-center"><img src="@/assets/img/part_iepir.png"></figure>-->
<!--              <a href="https://iepirkumi.lv/ru/drugije_servisi/kazahstan" target="_blank">JaCarta JC 100</a>-->
<!--              <p>{{ $t('For EU countries') }}</p>-->
<!--            </div>-->
            <div class="column">
              <figure class="image is-96x96 is-center" style="border: 1px solid #d0d1d1;padding: 40px 5px 0px;border-radius: 10px;"><img src="@/assets/img/part_thales.jpg"></figure>
              <a href="https://safenet.gemalto.com/multi-factor-authentication/authenticators/pki-usb-authentication/etoken-5110-usb-token/" target="_blank">SafeNet eToken 5110</a>
              <p>{{ $t('For other countries') }}</p>
            </div>
          </div>
        </div>

        <div style="height: 1rem;"></div>

      </div>
    </section>

  </div>
</template>
<script>
  export default {
    computed: {
      lang () {
        return this.$i18n.locale
      }
    }
  }
</script>
<style scoped>
  .is-center {
    margin: auto;
  }
</style>
